export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://35m4pl4iz6.execute-api.us-west-2.amazonaws.com/dev",
    APIURL: "https://nuaw0t6c87.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_OO8QcqQlY",
    APP_CLIENT_ID: "27v5cdv2nqhlkd723ibgu2lgrk",
    IDENTITY_POOL_ID: "us-west-2:7834655b-ef79-4e84-8dcb-c1c719dc534d"
  }
};
